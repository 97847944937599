import React, { FC, useState } from "react";

import { GetStaticProps } from "next";
import Link from "next/link";
import styled from "styled-components";

import Layout from "../components/Layout";
import { client } from "../shopify";
import { Collection as CollectionType, Product } from "../types";
import Currency from "../components/Currency";
import Unit from "../components/Unit";
import About from "../components/About";
import { SELECTORS } from "../breakpoints";
import LogoSvg from "../svg/logo_berkshire.svg";
import { parseUnit } from "../description";

const HomeLayout = styled.div`
  margin: 0;
`;

const ProductGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;

  margin: 0 0 20px 0;

  overflow: scroll;

  ${SELECTORS.DESKTOP} {
    justify-content: flex-start;
    overflow: hidden;
    flex-wrap: wrap;
  }
`;

const HeaderImage = styled((props) => <LogoSvg {...props} />)`
  position: static;
  top: 0;
  left: 0;

  margin: 5px;

  height: 60px;
  width: 210px;

  border-radius: 3px;
  overflow: hidden;

  user-select: none;

  ${SELECTORS.PHONE} {
    display: none;
  }
`;

const HeaderImageAnchor = styled.a``;

const ProductGridItem = styled.div`
  margin: 0 5px;
  border-radius: 3px;
  box-shadow: 0px 0px 2px 0px #000000a8;

  user-select: none;

  overflow: hidden;
  position: relative;

  width: 160px;
  height: 30vh;

  ${SELECTORS.PHONE} {
    width: 225px;
    height: 300px;
  }

  ${SELECTORS.DESKTOP} {
    width: 300px;
    height: 400px;

    margin: 15px;
  }
`;

const ProductImage = styled.img`
  height: 100%;
  width: 100%;
`;

const ProductInfo = styled.div`
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(255, 255, 255, 0) 35%,
    rgba(255, 255, 255, 0) 100%
  );

  color: white;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  padding: 5px;

  width: 100%;
`;

const ProductTitle = styled.h1`
  font-size: 1.5em;
`;

const CollectionTitle = styled.h1`
  font-size: 2em;

  ${SELECTORS.DESKTOP} {
    font-size: 3em;
  }
`;

const ProductPrice = styled.p`
  font-size: 1.3em;
`;

const CollectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 0 5px 5px 5px;

  border-bottom: 1px solid #0000001f;
`;

const Collection: FC<{ title: string; products: Product[] }> = ({
  products,
  title,
}) => (
  <>
    <CollectionHeader>
      <CollectionTitle>{title}</CollectionTitle>
    </CollectionHeader>

    <ProductGrid>
      {products.map((product, index) => (
        <Link key={`${index}`} href={`/produkter/${product.handle}`}>
          <a>
            <ProductGridItem>
              <ProductImage
                alt={product.imageAltText}
                src={`${product.imageUrl}`}
              />

              <ProductInfo>
                <ProductTitle>{product.title}</ProductTitle>

                <ProductPrice>
                  <Currency value={product.price} />
                  <Unit unit={product.unit} />
                </ProductPrice>
              </ProductInfo>
            </ProductGridItem>
          </a>
        </Link>
      ))}
    </ProductGrid>
  </>
);

const Home: FC<{ initialCollections: CollectionType[] }> = ({
  initialCollections,
}) => {
  // TODO(@benedicteb, 2021-03-10) Find appropirate type
  // eslint-disable-next-line
  const [collections] = useState<any[]>(initialCollections);

  const description =
    "I San Leucio har de vevet silke siden Bourboner-kongenes tid. Vi importerer direkte fra Italia og legger spesiell vekt på at stoffene og mønstrene skal ligne på de som ble brukt i folkedraktene for 250 år siden.";

  return (
    <Layout
      title={"Butikk"}
      description={description}
      inverted={true}
      canGoBack={false}
      socialImageAltText={
        "Flott rødt silkestoff henger over sjarmerende gammel tømmerdør"
      }
      socialImageUrl={`https://${process.env.NEXT_PUBLIC_DOMAIN}/images/red_fabric_door_30per_80.jpeg`}
      socialUrl={"/"}
    >
      <Link href={"/"} passHref>
        <HeaderImageAnchor>
          <HeaderImage />
        </HeaderImageAnchor>
      </Link>

      <HomeLayout>
        <About />

        {collections.map((collection, index) => (
          <Collection
            key={index}
            title={collection.title}
            products={collection.products}
          />
        ))}
      </HomeLayout>
    </Layout>
  );
};

const getStaticProps: GetStaticProps = async () => {
  const collections = await client.collection.fetchAllWithProducts();

  const initialCollections = collections
    .sort((a: any, b: any) => {
      if (a.title < b.title) {
        return -1;
      } else if (a.title > b.title) {
        return 1;
      }

      return 0;
    })
    .map((collection: any) => {
      // TODO(@benedicteb, 2021-03-17) Remove use of <any> type
      // eslint-disable-next-line
      const products = collection.products.map((product: any) => {
        // TODO(@benedicteb, 2021-03-11) Fix typings which mean image helpers arent there
        // eslint-disable-next-line
        // @ts-ignore
        const imageUrl = client.image.helpers.imageForSize(
          product.variants[0].image,
          {
            maxWidth: 500,
            maxHeight: 500,
          }
        );

        const unit = parseUnit(product.description);

        if (!unit) {
          return null;
        }

        return {
          imageUrl,

          title: product.title,
          // TODO(@benedicteb, 2021-03-11) Handle is an attribute under product
          // eslint-disable-next-line
          // @ts-ignore
          handle: product.handle,
          price: parseFloat(product.variants[0].price.amount),
          id: product.id,
          imageAltText: product.description.split(".")[0],
          unit,
        };
      });

      const nonNullProducts = products.filter(
        (product: Product | null) => product != null
      );

      return {
        title: collection.title.split(" ").slice(1).join(" "),
        products: nonNullProducts,
      };
    });

  const nonEmptyCollections = initialCollections.filter(
    (collection: CollectionType) => collection.products.length > 0
  );

  return { props: { initialCollections: nonEmptyCollections } };
};

export default Home;

export { getStaticProps };

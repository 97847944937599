import React, { FC } from "react";
import styled from "styled-components";
import { SELECTORS } from "../breakpoints";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin: 0 0 20px 0;

  ${SELECTORS.DESKTOP} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 15px 15px 15px;
  }
`;

const AboutImageWrapper = styled.div`
  overflow: hidden;

  position: relative;

  ${SELECTORS.PHONE} {
    height: 60vh;
    border-bottom: 1px solid #0000002b;
  }

  ${SELECTORS.DESKTOP} {
    max-width: 40vw;
  }
`;

const AboutImage = styled.img`
  border-radius: 3px;
  box-shadow: 0px 0px 2px 0px #000000a8;

  ${SELECTORS.PHONE} {
    border-radius: 0;
    width: 100%;
  }

  ${SELECTORS.DESKTOP} {
    height: 50vh;
  }
`;

const TextWrapper = styled.div`
  position: relative;

  margin: 0 15px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${SELECTORS.DESKTOP} {
    max-width: 600px;
  }
`;

const Header = styled.h1`
  font-size: 3em;

  margin: 0 0 15px 0;

  font-weight: lighter;

  ${SELECTORS.PHONE} {
    margin-top: 20px;
  }
`;

const Paragraph = styled.p`
  font-size: 1.3em;

  max-width: 800px;

  margin: 0 0 10px 0;
`;

const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  margin: 15px 0 0 0;

  ${SELECTORS.PHONE} {
    width: 70vw;
  }

  ${SELECTORS.DESKTOP} {
    max-width: 30vw;
  }
`;

const AuthorSignature = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const AuthorImage = styled.img`
  width: 70px;
  height: 70px;

  border-radius: 50px;
  box-shadow: 0px 0px 2px 0px #000000a8;
`;

const AuthorSignatureParagraph = styled.p`
  font-size: 1.2em;

  margin: 0 0 10px 10px;
`;

const About: FC = () => {
  return (
    <Wrapper>
      <AboutImageWrapper>
        <AboutImage
          alt="Flott rødt silkestoff henger over sjarmerende gammel tømmerdør"
          src="/images/red_fabric_door_30per_80.jpeg"
        />
      </AboutImageWrapper>

      <TextWrapper>
        <Header>«Hvete og erter og sove i silkeseng?»</Header>

        <Paragraph>
          I San Leucio har de vevet silke siden Bourboner- kongenes tid. Vi
          importerer direkte fra Italia og legger spesiell vekt på at stoffene
          og mønstrene skal ligne på de som ble brukt i folkedraktene for 250 år
          siden.
        </Paragraph>

        <Paragraph>
          Og om du, som grisen i Asbjørnsen og Moes folkeeventyr, vil forandre
          levemåten din, så kanskje et sengeteppe i silkebrokade ville være noe?
        </Paragraph>

        <Paragraph>
          Søre Brekka - Sus i Serken ligger på Lesja. Vi syr bunader og selger
          silkestoffer og bunadtilbehør.
        </Paragraph>

        <Paragraph>
          Vi jobber stadig med å utvide og forbedre utvalget av stoffer. Ta
          gjerne kontakt med oss hvis det er noe spesielt du ønsker deg som du
          ikke finner på sidene våre i dag.
        </Paragraph>

        <AuthorWrapper>
          <AuthorImage
            src={"/images/profile.jpeg"}
            alt={"Portrettbilde av Kristin"}
          />

          <AuthorSignature>
            <AuthorSignatureParagraph>Hilsen Kristin</AuthorSignatureParagraph>
            <AuthorSignatureParagraph>
              Grunnlegger og daglig leder for Sus i Serken
            </AuthorSignatureParagraph>
          </AuthorSignature>
        </AuthorWrapper>
      </TextWrapper>
    </Wrapper>
  );
};

export default About;
